// Blog Single Hero
.blog-single-hero {
  @include tablet {
    margin-bottom: 32px;
  }

  &--head {
    @include tablet {
      height: 290px;
    }

    &__title {
      @include tablet {
        font-size: 38px;
        line-height: 36px;
        padding: 0 40px;
      }
      @include mobile {
        font-size: 28px;
        line-height: 34px;
        padding: 0 10px;
        width: 100%;
      }
    }
  }
}

.blog-content-img-right {
  object-position: 100% 100%;
}

.blog-index-hero {
  &--content {
    &__title {
      @include tablet {
        font-size: 32px;
      }
    }
  }
}

// Blog Single Main
.blog-single-main {
  &--social {
    @include desktop {
      top: 10px;
      width: 100%;
    }
    @include mobile {
      margin-bottom: 60px;
    }
    &__list {
      @include desktop {
        flex-direction: row;
        width: 200px;
        justify-content: space-around;
        margin: 0 auto;
      }
    }
  }

  &--content {
    @include desktop {
      margin-top: 40px;
      max-width: 768px;
    }
    @include tablet {
      padding-bottom: 70px;
      margin-bottom: 42px;
    }

    &__feature-img {
      @include mobile {
        height: 300px;
      }
    }

    h4 {
      @include mobile {
        font-size: 20px;
      }
    }

    img {
      @include tablet {
        margin: 34px auto;
      }
    }
  }

  &--footer-content {
    @include desktop-hd {
      margin-right: 15px;
      margin-left: 15px;
    }
  }
  &--footer-content-item {
    @include desktop {
      padding: 0 8px;
    }
    @include tablet {
      width: 100%;
      padding: 0 60px;
      margin-bottom: 30px;
    }
    @include mobile {
      padding: 0;
    }

    &__title {
      @include tablet {
        height: auto;
      }
    }

    img {
      @include desktop {
        height: 200px;
      }
      @include tablet {
        height: 300px;
      }
      @include mobile {
        height: 242px;
      }
    }
  }
}

.blog-content-wrap {
  @include tablet {
    padding: 0 50px;
  }
  @include mobile {
    padding: 0 26px;
  }
}

// Blog Index
.inner-grid {
  @include laptop-hd {
    max-width: 1280px;
  }
}

.blog-index-main {
  &--content {
    @include desktop-hd {
      margin-right: 15px;
      margin-left: 15px;
    }
    @include tablet {
      padding: 0 60px;
    }
    @include mobile {
      padding: 0;
    }
  }

  &--content-item {
    @include desktop {
      padding: 0 8px;
    }
    @include tablet {
      width: 100%;
    }

    &__img {
      @include desktop {
        height: 200px;
      }
      @include tablet {
        height: 270px;
      }
      @include mobile {
        height: 240px;
      }
      @include mobile-ipx {
        height: 220px;
      }
    }

    &__title {
    }
  }
}
