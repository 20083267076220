// Terms Page
.welcome-main-hero {
  @include mobile {
    height: 100%;
  }
  &--content-left {
    @include laptop-hd {
      padding: 178px 0 145px 50px;
    }

    @include desktop {
      padding: 151px 54px 118px 50px;
    }

    @include tablet {
      padding: 138px 0 128px 24px;
      width: 40%;
    }

    @include mobile {
      padding: 66px 0 56px;
      width: 100%;
      text-align: center;
    }

    &__title-sm {
      @include tablet {
        font-size: 26px;
        margin-bottom: 6px;
      }
    }

    &__title {
      @include tablet {
        font-size: 38px;
      }
      @include mobile {
        font-size: 38px;
        line-height: 42px;
      }
    }

    .divider-gold {
      @include tablet {
        margin: 20px 0 36px;
      }

      @include mobile {
        margin: 22px auto 34px;
      }
    }

    &__text {
      @include desktop {
        width: auto;
      }

      @include tablet {
        font-size: 14px;
        line-height: 20px;
        max-width: 248px;
      }
      @include tablet {
        max-width: 100%;
      }
    }

    .btn-welcome-main {
      @include tablet {
        font-size: 14px;
        padding: 12px 20px;
      }
    }
    .arrow-down {
      @include desktop {
        bottom: -54px;
      }
      @include tablet {
        display: none;
      }
    }
  }

  &::before {
    @include tablet {
      height: 579px;
      width: 40%;
    }

    @include mobile {
      height: 534px;
      width: 100%;
    }
  }
  &--content-right {
    @include mobile {
      width: 100%;
    }

    &--card {
      @include laptop-hd {
        padding: 88px 63px;
        position: absolute;
        top: 14%;
        right: 14px;
        width: 105%;
      }

      @include desktop {
        padding: 54px 43px;
        top: 15%;
        right: 7px;
        width: 105%;
      }

      @include tablet {
        padding: 40px 43px;
        top: 14%;
        right: -68px;
        width: 122%;
      }

      @include mobile {
        position: relative;
        width: auto;
        margin: 0 20px;
        top: 0;
        right: 0;
      }

      p {
        @include tablet {
          font-size: 14px;
        }
      }
    }
  }
}

.welcome-form {
  @include desktop {
    width: 500px;
  }

  @include tablet {
    padding: 24px 40px 24px;
    width: 494px;
    height: 100%;
    margin: 0 auto 40px;
  }

  @include mobile {
    padding: 20px 24px 20px;
    width: 90%;
    height: 100%;
  }

  &--form-control {
    .input-container,
    label {
      @include desktop {
        width: 90%;
      }

      @include tablet {
        width: 100%;
      }
    }
  }

  &--btn-wrap {
    @include desktop {
      width: 80%;
    }

    @include tablet {
      width: 100%;
    }

    .btn-wf-terms {
      @include mobile-md {
        font-size: 13px;
        padding: 14px 10px;
        letter-spacing: -0.5px;
      }
    }
  }

  &.dispatch-submitting {
    .submit-notif-submitting {
      @include mobile-md {
        top: 31%;
      }
      @include mobile-sm {
        margin-left: -113px;
      }
    }
  }

  &.dispatch-success {
    .submit-notif-success {
      @include mobile-md {
        font-size: 14px;
        margin-left: -141px;
        letter-spacing: -0.5px;
        top: 31%;
      }
      @include mobile-sm {
        font-size: 13px;
        margin-left: -131px;
        letter-spacing: -0.5px;
      }
    }
  }

  .forms-quote {
    @include tablet {
      width: 540px;
      margin: 0 auto;
    }

    @include mobile {
      padding: 12px 24px 40px;
      width: 90%;
      height: 100%;
    }
  }
}

.forms-quote {
  .chkbox-container {
    .chkbox input:checked + label:after {
      @include desktop {
        top: 2px;
      }

      @include tablet {
        left: 12px;
        width: 9px;
        height: 23px;
        top: 0px;
      }
    }

    .form-notification {
      @include tablet {
        top: 104px;
      }
    }
  }

  .input-container {
    .form-notification {
      @include tablet {
        top: 39px;
      }
    }
  }

  .chkbox-grid {
    .chkbox input:checked + label:after {
      @include tablet {
        top: 10px;
      }
    }
  }
}

.austrac-form {
  @include desktop {
    padding: 16px 0 22px;
  }

  @include tablet {
    padding: 24px 40px 24px;
  }

  @include mobile {
    padding: 20px 24px 20px;
  }
}

.tnc-page-main {
  @include tablet {
    margin-top: 36px;
  }
  @include mobile {
    margin-top: 52px;
  }

  &--content {
    @include laptop-hd {
      padding: 110px 0 110px 50px;
    }
    @include tablet {
      padding: 82px 34px;
      width: 100%;
    }
    @include mobile {
      padding: 50px 30px;
    }

    &__title {
      @include mobile {
        font-size: 22px;
      }
    }

    .divider-gold {
      margin: 20px 0 34px;
    }

    &__list {
      @include desktop-hd {
        width: 706px;
      }
      @include desktop {
        width: 544px;
      }
      @include tablet {
        width: 100%;
      }

      p {
        @include tablet {
          margin-bottom: 20px;
        }

        @include mobile {
          padding-left: 26px;
        }
      }
    }
  }

  &__img-bg {
    @include tablet {
      display: none;
    }
  }
}

.tnc-page-main--content .content-scroll {
  @include desktop-hd {
    overflow-x: hidden;
  }
}

.tnc-page-sa-clauses {
  @include tablet {
    -webkit-box-shadow: 0px 4px 10px -6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 4px 10px -6px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 4px 10px -6px rgba(0, 0, 0, 0.75);
  }
  @include tablet {
    background: #fff;
  }
  &__img-bg {
    @include tablet {
      display: none;
    }
  }

  &--content {
    @include tablet {
      padding: 68px 30px 68px 30px;
      width: 100%;
    }

    @include mobile {
      padding: 64px 30px 50px;
      width: 100%;
    }

    .divider-gold {
      @include tablet {
        margin: 20px 0 34px;
      }
    }

    &__title {
      @include mobile {
        font-size: 22px;
      }
    }

    &__subtitle {
      @include tablet {
        font-size: 16px;
        margin-bottom: 24px;
        padding-top: 0;
      }
    }

    &__list {
      @include laptop-hd {
        width: 800px;
      }

      @include desktop-hd {
        width: 742px;
      }

      @include desktop {
        width: 584px;
      }

      @include tablet {
        width: 98%;
      }
    }

    p {
      @include tablet {
        margin-bottom: 20px;
      }
    }

    .content-scroll {
      @include desktop-hd {
        height: 416px;
      }

      @include tablet {
        height: 652px !important;
      }
    }
  }
}

.tnc-page-sa-form {
  &--wrap {
    @include tablet {
      flex-direction: column-reverse;
    }
  }

  &--content {
    @include laptop-hd {
      padding: 0 50px;
    }

    @include desktop {
      padding: 0 24px;
    }

    @include tablet {
      margin-bottom: 0;
      width: 100%;
    }

    &__title {
      text-align: center;
    }

    p {
      text-align: justify;
    }

    &__text-alt {
      @include tablet {
        display: none;
      }
    }
  }
  &--form {
    @include tablet {
      width: 100%;
    }

    &__text-alt {
      @include tablet {
        display: block;
        padding: 20px 100px;
        text-align: center;
        font-family: "Montserrat Light";
        line-height: 2em;
      }
      @include mobile {
        padding: 20px 42px;
      }
    }
    .forms-quote {
      input {
        @include mobile {
        }
      }

      label {
        @include mobile {
        }
      }
    }
  }
}

// Austac page
.austrac-hero {
  @include tablet {
    height: 494px;
  }
  @include mobile {
    height: 100%;
  }

  &::after {
    @include laptop-hd2 {
      background: url(/images/welcome/indentify-business-hero-img-hd2.jpg)
        no-repeat;
    }
    @include desktop {
      background: url(/images/welcome/indentify-business-hero-img-hd.jpg)
        no-repeat;
    }
    @include tablet {
      background: url(/images/welcome/indentify-business-hero-img-tablet.jpg)
        no-repeat;
    }
  }

  &--content-left {
    @include laptop-hd {
      padding: 208px 0 223px 50px;
    }
    @include desktop {
      padding: 164px 54px 189px 50px;
    }
    @include tablet {
      padding: 146px 30px 156px;
    }
    @include mobile {
      padding: 144px 30px 114px;
    }
  }

  &--content-right {
    @include mobile {
      display: none;
    }

    &__austrac-img {
      @include desktop {
        right: 2px;
        top: 34px;
        width: 105%;
        height: 86%;
      }

      @include tablet {
        right: -77px;
        top: 30px;
        width: 123%;
        height: 90%;
      }

      @include mobile {
        top: 0;
        right: 0;
        position: relative;
        width: 90%;
      }
    }
  }

  &::before {
    @include mobile {
      height: 480px;
    }
  }

  &::after {
    @include desktop {
      height: 680px;
    }

    @include tablet {
      height: 579px;
      width: 60%;
    }

    @include mobile {
      display: none;
    }

    @include mobile-sm {
      height: 490px;
    }
  }
}

.austrac-main {
  @include laptop-hd {
    margin: 170px 0 150px;
  }

  @include desktop {
    margin: 140px 0 100px;
  }

  @include tablet {
    margin: 40px 0 100px;
  }
  &--content {
    @include laptop-hd {
      padding-left: 50px;
      padding-right: 18px;
    }

    @include desktop {
      padding-right: 38px;
    }

    @include tablet {
      padding: 0 50px;
      margin-bottom: 80px;
      width: 100%;
    }

    @include mobile {
      padding: 0 30px;
    }
  }

  &--form {
    @include tablet {
      width: 100%;
    }
  }
}

.business-type-hero {
  &--content-left {
    @include laptop-hd {
      padding: 200px 0 196px 50px;
    }

    @include desktop {
      padding: 148px 16px 130px 50px;
    }

    @include tablet {
      padding: 180px 30px 182px 30px;
    }

    @include mobile {
      padding: 130px 26px 80px;
    }
  }

  &--content-right {
    @include tablet {
      width: 60%;
    }
    @include mobile {
      display: none;
    }

    img {
      @include laptop-hd {
        height: 94%;
      }
      @include desktop {
        height: 92%;
      }

      @include tablet {
        height: 494px;
        width: 120%;
      }

      @include mobile {
        display: none;
      }
    }
  }

  &::before {
    @include desktop {
      height: 640px;
    }
    @include mobile {
      height: 467px;
    }
  }

  &::after {
    @include desktop {
      height: 640px;
    }

    @include tablet {
      width: 60%;
    }

    @include mobile {
      display: none;
    }

    @include mobile-sm {
      height: 490px;
    }
  }
}

.special-event-hero {
  &--content-left {
    @include laptop-hd {
      padding: 160px 50px 158px 50px;
    }

    @include desktop {
      padding: 140px 50px 138px 28px;
    }

    @include tablet {
      padding: 182px 20px 182px 24px;
    }

    @include mobile {
      padding: 138px 14px 106px;
    }

    &__title {
      @include mobile-sm {
        line-height: 40px;
      }
    }
  }

  &--content-right {
    @include mobile {
      display: none;
    }
  }

  &::after {
    @include desktop {
      background: url("https://securecash.com.au/images/welcome/special-event-img-tablet.jpg")
        no-repeat;
    }
  }
}

// Site info

.site-info-form {
  @include mobile {
    margin: 74px 0;
  }

  &--wrap {
    @include tablet {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &--content {
    @include laptop-hd {
      padding-left: 38px;
    }
    @include tablet {
      margin-bottom: 60px;
      width: 100%;
    }

    &__title {
      @include tablet {
        padding-right: 0;
      }
    }
  }

  &--form {
    @include tablet {
      width: 100%;
    }
  }

  &--form-wrapper {
    @include desktop {
      height: 770px;
    }
    @include tablet {
      height: 760px;
    }
    @include mobile-md {
      height: 776px;
    }
    @include mobile-md {
      height: 782px;
    }
  }
}

.welcome-info-form {
  @include desktop {
    padding-bottom: 32px;
    width: 500px;
  }
  @include tablet {
    padding-bottom: 32px;
    width: 530px;
  }
  @include mobile {
    width: 420px;
  }
  @include mobile-md {
    width: 380px;
  }

  .form-slider {
    .form-slides {
      .form-slide {
        @include mobile {
          width: 33.34%;
        }

        .form-slide-btn-wrap {
          button {
            @include desktop {
              left: 20px;
            }
            @include tablet {
              left: 0;
            }
            @include mobile {
              font-size: 20px;
              top: 10px;
            }

            span {
              @include mobile {
                display: none;
              }
            }
          }
        }

        &__title {
          @include mobile {
            margin-top: 6px;
          }
        }
        &__title-mt-adjust {
          @include mobile {
            margin-top: 38px;
          }
        }

        &__title-pg2mv-adjust {
          @include mobile {
            margin-top: 32px;
          }
          @include mobile-sm {
            margin-top: 38px;
          }
        }
      }
    }
  }
  .wf-chkbox-week {
    .wf-form-label {
      @include mobile {
        font-size: 15px;
      }
    }
    .wf-chkbox-wrapper {
      .wf-chkbox {
        @include mobile {
          font-size: 15px;
        }
      }
    }
  }

  .wf-chkbox-container {
    .wf-chkbox-wrapper {
      .wf-chkbox {
        @include mobile {
          margin-top: 7px;
          padding-left: 26px;
          padding-top: 0;
        }

        .chkmark {
          @include mobile {
            height: 21px;
            width: 21px;
          }

          &::after {
            @include mobile {
              left: 5px;
              top: 1px;
              width: 4px;
              height: 11px;
            }
          }
        }
      }
    }

    .wf-chkbox-footnote {
      @include mobile {
        font-size: 13px;
        margin-left: 10px;
      }
    }

    .form-notification {
      @include mobile {
        top: 107px;
      }
    }
  }

  .wf-chkbox-week {
    .form-notification {
      @include mobile {
        top: 174px;
      }
    }
  }

  .welcome-form--form-split {
    @include desktop {
      width: 90%;
    }
    @include tablet {
      margin: 0;
      width: 100%;
    }
    @include mobile {
      flex-wrap: wrap;
    }

    .welcome-form--form-split-control {
      @include mobile {
        width: 100%;
      }
    }
    .split-control_post_code {
      @include mobile {
        margin: 0;
      }
    }
  }

  .wf-chkbox-container {
    @include desktop {
      width: 90%;
    }
    @include tablet {
      width: 100%;
    }
  }
}

.special-event--wrapper {
  @include desktop {
    height: 710px;
  }
  @include tablet {
    height: 690px;
  }
  @include mobile {
    height: 700px;
  }
  @include mobile-sm {
    height: 716px;
  }

  .welcome-info-form {
    .form-slides .form-slide__title-mt-adjust {
      @include mobile {
        margin-top: 16px;
      }
      @include mobile-md {
        margin-top: 10px;
      }
    }

    .form-slides .form-slide__title-pg2mv-adjust {
      @include mobile {
        margin-top: 10px;
      }
    }

    .form-slides .form-slide__title-pg3mv-adjust {
      @include mobile {
        margin-top: 18px;
      }
      @include mobile-md {
        margin-top: 10px;
      }
    }
  }
}
//
.welcome-info-form-site-risk {
  .welcome-form--form-control {
    .input-container-select {
      .select-wrap {
        @include desktop-hd {
          width: 45%;
        }
        @include desktop {
          width: 55%;
        }
        @include tablet {
          width: 100%;
        }

        select {
          @include tablet {
            max-width: none !important;
          }
        }
      }

      .form-notification {
        @include desktop {
          right: -4%;
          top: 54px;
          width: 46%;
        }
        @include tablet {
          left: 6%;
          top: 112px;
          width: 380px;
        }
        @include mobile {
          width: 88%;
        }
        @include mobile-md {
          top: 130px;
        }

        &::before {
          @include desktop {
            top: 20px;
          }
          @include tablet {
            left: 14px;
            top: -16px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #696464;
          }
        }
        &::after {
          @include desktop {
            top: 20px;
          }
          @include tablet {
            top: -15px;
            left: 14px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #fff;
          }
        }
      }
    }
  }

  .input-container-select-aac {
    .select-wrap {
      &::after {
        @include laptop-hd {
          right: 40px !important;
        }

        @include desktop-hd {
          right: 3% !important;
          margin-right: 0px;
        }
        @include desktop {
          margin-right: 2px;
        }
        @include tablet {
          margin-right: -6px;
        }
        @include mobile {
          margin-right: -2px;
        }
      }
    }
  }

  .wf-chkbox-container {
    .wf-chkbox-wrapper {
      @include mobile {
        margin-left: 12px;
      }

      .wf-chkbox {
        @include mobile {
          line-height: 22px;
          padding-left: 28px;
        }

        .chkmark {
          @include mobile {
            height: 21px;
            width: 21px;
          }

          &::after {
            @include mobile {
              left: 6px;
              top: 1px;
              width: 3px;
              height: 11px;
            }
          }
        }
      }
    }
  }
  .hazard-form--btn-wrap {
    @include desktop {
      width: 100%;
    }
  }
}

.submit-status {
  &.dispatch-success {
    .submit-notif-success {
      @include mobile {
        font-size: 13px !important;
        margin-left: -129px;
        letter-spacing: -0.5px;
        top: 34%;
      }
    }
  }
}

.submit-status-alt {
  &.dispatch-success {
    .hazard-form--btn-wrap {
      .submit-notif-success {
        @include mobile-sm {
          font-size: 14px !important;
          margin-left: -147px;
          letter-spacing: -0.1px;
          margin-top: -13px;
        }
      }
    }

    .site-info-form--btn-wrap {
      .submit-notif-success {
        @include mobile-sm {
          font-size: 13px !important;
          margin-left: -138px !important;
          margin-top: -6px;
          letter-spacing: 0;
        }
      }
    }
  }
}

// Contact Page
.contact-page {
  @include tablet {
    padding: 60px 0;
  }

  @include mobile {
    background-position: 60% !important;
    background-size: cover !important;
  }
}

#contact-form-form-panel {
  .content {
    @include mobile {
      margin: 56px auto;
      width: 90%;
    }
  }
}

.form-site-risk-area {
  @include mobile {
    margin-bottom: 0;
  }

  &--content {
    @include tablet {
      padding: 20px 80px 40px;
    }

    @include mobile {
      padding: 20px 20px 40px;
    }

    .content-scroll {
      @include tablet {
        padding-right: 0;
      }

      @include desktop {
        height: 450px;
      }

      @include tablet {
        height: 100% !important;
      }
    }
  }
}

.contact--form {
  .input-container-contact-chkbox {
    .chkbox {
      input[type="checkbox"] + label span {
        @include desktop {
          width: 28px;
          height: 28px;
          padding: 0;
          margin: 0;
          margin-right: 5px;
        }
      }

      input[type="checkbox"] + label::after {
        top: 0px;
        height: 18px;
        width: 8px;
        left: 8px;
      }
    }
  }

  #callback-option-container {
    @include mobile {
      margin: 36px auto;
    }
  }
  .input-container-contact--textarea {
    @include mobile {
      padding-top: 1px;
    }
  }

  .input-container-contact-chkbox {
    @include mobile {
      padding-bottom: 1px;
    }
  }

  .contact--form-btn-wrap {
    .submit-notif-submitting {
    }

    .submit-notif-success {
      @include mobile {
        font-size: 13px !important;
        margin-left: -132px;
        margin-top: -6px;
      }
    }
  }
}
// To be deleted
#contact-testimonial-section {
  #testimonial-carousel-container {
    .scroll-control-mobile {
      @include mobile {
        top: calc(43%);
      }
    }
  }

  #testimonial-carousel {
    @include desktop {
      height: 260px;
    }
    @include mobile {
      height: 288px;
      margin-top: 0;
    }
    @include mobile-md {
      height: 316px;
      margin-top: 0;
    }
    @include mobile-sm {
      height: 372px;
      margin-top: 0;
    }

    .item {
      .content {
        margin-top: 0;

        .wrapper {
          @include tablet {
            margin: auto;
          }

          .excerpt {
            @include desktop {
              width: 70%;
              overflow: initial;
            }
            @include tablet {
              width: 82%;
            }
            @include mobile {
              margin-bottom: 0;
              width: 90%;
            }

            p {
              @include mobile {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

#contact-form-info-panel {
  @include mobile-md {
    padding-left: 8%;
    padding-right: 8%;
  }

  .content {
    h3 {
      @include mobile-md {
        font-size: 30px;
      }
    }

    .info-wrapper {
      span {
        @include mobile-md {
          font-size: 15px;
        }
        @include mobile-md {
          font-size: 14px;
        }
      }
    }
  }
}

// Updated testimonial slider
.contact-testimonial {
  @include laptop-hd2 {
    background: url(../images/mainbg-contact-lg.jpg) no-repeat;
    height: 630px;
    padding-top: 110px;
  }
  @include desktop {
    height: 604px;
    padding-top: 84px;
  }
  @include tablet {
    height: 626px;
    padding-top: 80px;
  }
  @include mobile {
    padding-top: 56px;
  }
  @include mobile-md {
    height: 630px;
    padding-top: 40px;
  }
  @include mobile-sm {
    padding-top: 20px;
  }

  &--title-wrap {
    p {
      @include mobile-sm {
        padding: 12px;
      }
    }
  }

  &--carousel-container {
    @include tablet {
      height: 220px;
    }
    @include mobile {
      height: 240px;
    }
    @include mobile-sm {
      height: 260px;
    }
  }

  &--carousel {
    @include mobile {
      width: 98%;
    }

    &__items {
      .carousel-item {
        .excerpt {
          @include tablet {
            width: 68%;
          }
          @include mobile {
            width: 77%;
          }
          @include mobile-sm {
            width: 72%;
          }

          p {
            &:before {
              @include mobile {
                font-size: 120px;
                left: -50px;
              }
            }
            &:after {
              @include mobile {
                font-size: 120px;
                right: -50px;
                bottom: -47px;
              }
            }
          }
        }
      }
    }
  }
}

.contact-testimonial
  #about-us-team-slider
  .team-carousel-wrapper
  .team-about-us-profile-label {
  display: none;
}
// About Us Team Slider
.team-carousel {
  @include laptop-hd {
    height: 697px;
  }
  @include desktop-hd {
    height: 760px;
  }
  @include desktop {
    height: 869px;
  }
  @include tablet {
    height: 1116px;
  }
  @include mobile {
    height: 1026px;
  }
  @include mobile-md {
    height: 1056px;
  }
  @include mobile-ipx {
    height: 1074px !important;
  }
}
// #about-us-team-slider .team-carousel-wrapper .team-carousel .team-item-wrapper .item-container
#about-us-team-slider {
  & > .inner .team-carousel-wrapper {
    width: calc(100% - 56px);
  }

  .team-carousel-wrapper {
    .team-carousel {
      @include mobile-sm {
        height: 1122px;
      }

      .team-item-wrapper {
        @include laptop-hd2 {
          min-width: 45%;
          width: 45%;
          margin-left: 2.5%;
          margin-right: 2.5%;
        }
        @include desktop {
          height: 93%;
        }
        @include tablet {
          height: 92%;
        }
        @include tablet-2 {
          height: 95%;
        }
        @include mobile {
          min-width: 95%;
          width: 95%;
          height: 94.5%;
        }

        .item-container {
          @include tablet {
            width: 303px !important;
          }
          @include tablet-2 {
            width: 282px !important;
          }
          @include mobile {
            width: 100% !important;
          }
        }

        .team-item-about-content {
          @include desktop {
            height: 100%;
            justify-content: center;
          }
          @include tablet {
            width: 100%;
            overflow: hidden;
          }
          @include mobile {
            padding-bottom: 30px;
          }
          @include mobile-ipx {
            display: flex;
          }
          @include mobile-sm {
            display: flex;
          }

          .wrapper {
            @include desktop {
              overflow: hidden;
              border-right: none;
              height: 100%;
              padding: 0;
              width: 84%;
            }
            @include tablet {
              max-height: 100%;
              width: 86%;
            }
            @include mobile {
              width: 85%;
            }
            @include mobile-md {
              width: 88%;
            }
            @include mobile-sm {
              margin-left: 20px;
              padding-right: 20px;
              overflow-x: hidden;
              width: 93%;
            }

            &::before {
              @include desktop {
                height: 308px;
                margin-right: 24px;
                margin-bottom: 0px;
                width: 151px;
              }
              @include tablet {
                height: 360px;
                margin-right: 30px;
                margin-bottom: 10px;
                width: 261px;
              }
              @include mobile {
                height: 410px;
                margin-right: 0;
                margin-bottom: 26px;
                width: 330px;
              }
              @include mobile-md {
                height: 375px;
                width: 292px;
              }
              @include mobile-ipx {
                height: 360px;
              }
              @include mobile-sm {
                height: 343px;
                width: 254px;
              }
            }

            p {
              @include desktop {
                margin-top: 34px;
              }
              @include tablet {
                margin-top: 30px;
              }
              @include mobile {
                margin-bottom: 8px;
              }
            }
          }
        }

        .team-about-us-profile-label {
          @include mobile-ipx {
            display: none;
          }
          @include mobile-s7 {
            display: none;
          }
        }
      }
    }
  }
}

#banner {
  & #banner-slider .slideshow-container h3.prata {
    @include mobile {
      line-height: 29px;
      margin: 0 auto 24px auto;
    }
  }

  & #banner-slider .slideshow-container h1.montSemi {
    @include mobile {
      line-height: 29px;
    }
  }

  .bannerSlides {
    img {
      @include mobile {
        min-height: 424px;
        object-fit: cover;
      }
      @include mobile-s7 {
        min-height: 418px;
      }
    }

    .inner {
      .text {
        h1 {
          @include mobile-sm {
            padding-right: 20px;
          }
        }

        p {
          @include mobile {
            font-size: 17px !important;
            line-height: 24px;
          }
        }

        .btn-learn-more {
          @include mobile {
            width: 208px;
          }

          p {
            @include mobile {
              font-size: 18px !important;
            }
          }
        }
      }

      .text > p {
        @include mobile-sm {
          padding-right: 20px;
        }
      }

      .pd-r-1 {
        @include mobile-sm {
          padding-right: 20px;
        }
      }
    }
  }
}

// Modal
.business-type-dailog-box--card {
  @include tablet {
    padding: 76px 60px;
  }

  @include mobile {
    padding: 50px 24px;
  }
  @include mobile-sm {
    padding: 46px 20px;
  }

  &__btn-wrap {
    @include tablet {
      padding: 22px 22px 0;
    }
    @include mobile {
      padding: 18px 12px 0;
    }
    @include mobile-md {
      padding: 6px 20px 0;
    }
    @include mobile-sm {
      padding: 6px 4px 0;
    }

    .btn-location-submit {
      @include mobile-md {
        height: 54px;
        width: 144px;
      }
    }
  }
}

.business-type-thank-you {
  &--card {
    @include tablet {
      padding: 54px 60px;
    }
    @include mobile {
      padding: 38px 30px;
    }
    @include mobile-md {
      padding: 30px 20px;
    }
    @include mobile-sm {
      padding: 34px 16px;
    }

    &__text-strong {
      @include mobile-sm {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__text {
      @include mobile-sm {
        font-size: 16px;
      }
    }

    &__btn-wrap {
      padding: 20px 22px 0;
    }
  }
}

body.modal-on,
body.ty-modal-on {
  .site-modal-outer {
    .site-modal-inner,
    .ty-site-innner {
      @include tablet {
        width: 700px;
        height: 452px;
      }
      @include mobile {
        width: 460px;
        height: 452px;
      }
      @include mobile-md {
        width: 403px;
        height: 452px;
      }
      @include mobile-sm {
        width: 350px;
        height: 452px;
      }
    }
  }
}

#content-middle {
  @include mobile {
    min-height: 556px;
  }
  @include mobile-md {
    min-height: 614px;
  }

  .content-middle-wraper {
    @include mobile {
      padding: 40px 0;
    }
  }
}

.home-video-section {
  &::before {
    @include mobile {
      background: #1a1a1a;
      content: "";
      width: 100%;
      height: 210px;
      display: block;
      position: absolute;
    }
  }

  #video-section {
    @include tablet {
      max-height: 555px;
      height: 505px;
    }
    @include mobile {
      max-height: 302px;
      height: 252px;
    }

    div.video-container {
      .video-player {
        @include mobile {
          height: 279px;
        }
        @include mobile-md {
          height: 248px;
        }
        @include mobile-sm {
          height: 220px;
        }
        @include mobile-s7 {
          height: 221px;
        }
      }
    }
  }
}

.forms-quote {
  .chkbox-grid {
    @include desktop {
      justify-content: space-around;
    }

    .chkbox input:checked + label:after {
      @include desktop {
        top: 11px;
      }
    }
  }
}

#left-content-text-wrapper {
  @include laptop-hd {
    padding-left: 12px;
    padding-right: 12px;
  }
}

#quote-content {
  #quote-content-right {
    .quote-page-form {
      @include desktop {
        width: 450px;
      }
      @include tablet {
        margin-bottom: 60px;
        width: auto;
      }
    }
  }
}

#about-us-banner-bottom {
  div {
    h2 {
      @include mobile {
        line-height: 1.25em;
        margin-bottom: 10px;
        text-align: center;
      }
    }
  }
}

// Welcome
#welcome-header-section {
  @include laptop-hd {
    height: 82vh;
  }
  @include desktop {
    height: 550px;
  }
  @include tablet {
    height: 584px;
  }
  @include mobile {
    height: 450px;
  }
}

#welcome-header-text-wrapper {
  @include desktop {
    margin-top: 22px;
    width: 50%;
  }
  @include tablet {
    margin-top: 0;
    width: 100%;
  }

  h3.prata2 {
    @include laptop-hd2 {
      left: -4%;
      margin-left: 0px;
      letter-spacing: -3px;
    }
    @include laptop-hd {
      font-size: 141px !important;
      top: -38px;
    }
    @include desktop-hd {
      letter-spacing: -5px;
    }
    @include desktop {
      font-size: 112px !important;
      margin-left: -6px;
      top: -94px;
    }
    @include tablet {
      font-size: 117px !important;
      margin-left: 24px;
    }
    @include mobile {
      font-size: 82px !important;
      letter-spacing: -2px;
      margin-left: 36px;
      top: -80px;
    }
    @include mobile-md {
      margin-left: 10px;
    }
    @include mobile-sm {
      font-size: 72px !important;
    }
  }

  h1 {
    @include laptop-hd2 {
      left: -4%;
      margin-left: 126px;
    }
    @include laptop-hd {
      font-size: 67px;
      top: 15%;
      margin-top: 28px;
      margin-left: 112px;
    }
    @include desktop-hd {
      margin-top: 32px;
    }
    @include desktop {
      font-size: 50px;
      margin-top: -38px;
      margin-left: 82px;
    }
    @include tablet {
      margin-left: 118px;
      font-size: 54px;
      margin-top: -20px;
    }
    @include mobile {
      font-size: 40px;
      top: 14px;
      margin-left: 100px;
    }
    @include mobile-md {
      font-size: 40px;
      top: 14px;
      margin-left: 76px;
    }
    @include mobile-sm {
      font-size: 36px;
      top: 4px;
      margin-left: 66px;
    }
  }

  hr {
    @include laptop-hd {
      margin-top: 190px;
      margin-bottom: 26px;
    }
    @include desktop {
      margin-top: 100px;
      margin-bottom: 0px;
    }
    @include tablet {
      margin-left: 0;
    }
    @include mobile {
      margin-top: 64px;
      margin-left: 20px;
    }
  }

  p {
    @include desktop {
      margin-bottom: 0;
      min-height: auto;
      padding: 24px 24px 30px 0;
    }
    @include tablet {
      text-align: left;
      width: 72%;
    }
    @include mobile {
      padding: 24px 24px 30px;
      width: 100%;
    }
  }

  .btn-welcome-hero {
    @include tablet {
      height: auto;
      width: auto;
      padding: 21px 51px;
      position: absolute;
      line-height: 18px;
      left: 0;
    }
    @include mobile {
      left: 20px;
    }
    @include mobile-md {
      padding: 16px 42px;
    }
  }
}

.welcome-image-wrapper {
  @include desktop {
    padding-left: 0;
  }
  @include tablet {
    display: none;
  }

  img {
    @include laptop-hd2 {
      width: 104%;
    }
    @include desktop {
      width: 100%;
      margin-left: 20px;
    }
  }
}

#black-box {
  @include laptop-hd {
    width: calc((100% / 2) + 36px);
  }
  @include tablet {
    width: 96%;
    margin: 0 auto;
    border-radius: 5px;
    padding: 98px 28px;
  }
  @include mobile {
    padding: 60px 28px;
  }
  @include mobile-md {
    padding: 60px 28px;
  }

  .content {
    @include laptop-hd {
      max-width: 578px;
      margin-right: 60px;
    }
    @include desktop-hd {
      max-width: 554px;
      margin-right: 46px;
    }
    @include desktop {
      padding: 98px 28px;
    }
    @include tablet {
      margin: 0;
      max-width: calc(100% - 5%);
      padding: 0;
    }
  }
}

#instruction-box {
  @include laptop-hd {
    margin-right: calc((1366px - (1366px * 0.98)) / 2);
    max-width: calc(1366px / 2);
  }
  @include desktop-hd {
    margin-right: calc((1366px - (1366px * 0.99)) / 2);
    max-width: 50%;
  }
  @include tablet {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }
}

// Live site update
#space-white {
  @include desktop-hd {
    padding-right: 8px;
  }
}

.home-services-section {
  @include desktop-hd {
    padding-left: 14px;
  }
}

.members-slider {
  @include desktop-hd {
    width: 1161px;
  }
}

#banner #banner-slider .slideshow-container .d-btn {
  @include tablet {
    padding: 0 20px;
    min-height: 54px;
  }
}

div.btn-learn-more {
  @include tablet-2 {
    min-width: 164px;
    width: 164px;
  }
}

// About page Hero

#headline-content {
  @include desktop {
    height: 454px;
  }
  @include tablet {
    height: 412px;
  }
  @include tablet-2 {
    height: 377px;
  }
  @include mobile {
    height: 406px;
  }
}
.about-hero {
  .bg-dots {
    @include mobile {
      background-size: cover;
    }
  }

  @include desktop {
    margin-bottom: 110px;
  }
  @include tablet {
    margin-bottom: 132px;
  }
  @include mobile {
    margin-bottom: 102px;
    background: linear-gradient(92deg, #dfdfdf 50%, #ffffff);
  }
  @include mobile-ipx {
    margin-bottom: 100px;
  }
  @include mobile-sm2 {
    margin-bottom: 116px;
  }
  @include mobile-sm {
    margin-bottom: 116px;
  }

  &--content {
    @include laptop-hd {
      padding: 100px 94px 100px 34px;
      width: 34.4%;
    }
    @include desktop-hd {
      padding: 100px 24px 100px 34px;
      width: 30%;
    }
    @include desktop {
      padding: 59px 10px 100px 24px;
      width: 32.5%;
    }
    @include tablet {
      padding: 23px 0px 100px 20px;
      width: 38.25%;
    }
    @include tablet-2 {
      padding: 12px 0px 100px 20px;
      width: 41%;
    }
    @include mobile {
      padding: 30px 30px;
      width: 100%;
    }

    hr.divider-gold {
      @include tablet {
        display: none;
      }
      @include mobile {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    h1 {
      @include mobile {
        text-align: center;
      }
    }

    p {
      @include mobile {
        text-align: center;
      }
    }

    .callback-button {
      @include mobile {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &--img-wrap {
    @include mobile {
      display: none;
    }

    img {
      @include desktop {
        width: 680px;
      }
      @include tablet {
        width: 474px;
        padding-top: 94px;
      }
      @include tablet-2 {
        width: 424px;
        padding-top: 94px;
      }
    }
  }

  &--banner-wrap {
    @include desktop {
      bottom: -62px;
    }
    @include tablet {
      bottom: -84px;
    }
    @include tablet-2 {
      bottom: -60px;
    }
    @include mobile {
      bottom: -140px;
    }
    @include mobile-ipx {
      bottom: -120px;
    }
  }

  #banner-info {
    @include tablet {
      padding: 16px 0;
    }
  }
}

#about-us-section-help {
  @include mobile {
    margin-top: 0px;
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    background: none;
  }
}

#about-us-section-service .section-col,
#about-us-section-help .section-col {
  @include mobile {
    height: 100%;
  }
}

#about-us-section-help .section-col {
  @include mobile {
    flex-direction: column;
    margin-top: 0px;
  }
}

.section-content-wrapper img {
  @include mobile {
    margin-top: 0px;
  }
}

.about-us-section {
  @include mobile {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .section-header {
    @include mobile {
      position: relative;
      top: -1px;
      text-align: center;
      padding: 18px 40px;
    }
  }

  .section-content {
    @include mobile {
      margin-top: 0;
      padding-bottom: 16px;
    }

    div.callback-button {
      @include mobile {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      }
    }
  }
}

#about-us-section-story {
  @include mobile {
    margin-bottom: 0px;
    height: auto;
    margin-top: 0px;
    flex-direction: column-reverse;
  }
}

#about-us-section-service {
  @include mobile {
    margin-top: 0;
    height: auto;
    background: none;
  }

  .section-col {
    @include desktop {
      height: 474px;
    }
    @include tablet {
      height: 354px;
    }
    @include mobile {
      height: 100%;
      flex-direction: column;
      margin-top: 0px;
    }
  }
}

// Partner Page
.partners-hero {
  &--header {
    h1 {
      @include tablet {
        padding: 0 30px;
      }
      @include mobile-sm {
        padding: 0;
      }
    }

    h3 {
      @include tablet {
        text-align: center;
      }
    }

    p {
    }
  }
}

.partners-section-wrapper {
  .content-wrapper {
    @include mobile {
      width: 100% !important;
    }
  }
}

.partners {
  &--flag-wrap {
    @include mobile {
      padding: 0;
    }
  }

  &--item {
    &__partner {
      img {
        @include tablet {
          float: none;
          display: block;
          margin: 30px 0;
        }
        @include mobile {
          margin: 30px auto !important;
        }
      }

      @include tablet {
        .rowluk-img,
        .abir-img,
        .corporate-uk-img,
        .protective-img {
          width: 90% !important;
        }

        .avertic_img {
          width: 60% !important;
        }
      }
      @include mobile {
      }

      ul {
        @include mobile-sm {
          font-size: 14px;
          padding-left: 20px;
        }
      }
    }
  }
}

#video-section {
  div.video-container {
    .video-player {
      @include mobile {
        height: 280px;
      }
      @include mobile-md {
        height: 250px;
      }
      @include mobile-ipx {
        height: 240px;
      }
      @include mobile-sm {
        height: 220px;
      }
      @include mobile-s7 {
        height: 210px;
      }
    }
  }
}

.banking-pickups-section-two {
  #faq {
    .col-right {
      .scrollable-content {
      }
    }
  }
}

// New updated services
#pitch {
  @include mobile {
    margin-bottom: 20px;
  }

  .col-right {
    .cta-box {
      .col-img {
        @include desktop-hd {
          height: 96%;
        }
        @include desktop {
          height: 102%;
        }
        @include tablet {
          height: 580px;
        }
        @include mobile-ipx {
          height: 540px;
        }
      }

      .cta-overlay {
        @include desktop-hd {
          height: 96%;
        }
        @include desktop {
          height: 102%;
        }
        @include tablet {
          height: 100%;
        }
      }
    }
  }
}

#faq {
  .col-right {
    @include tablet {
      margin-bottom: 40px;
    }
  }
}

#guarantee {
  @include tablet {
    margin-bottom: 60px;
  }
  @include mobile {
    margin-top: 40px;
  }

  .col-right {
    .cta-box {
      .col-img {
        @include mobile {
          height: 560px;
        }
        @include mobile-md {
          height: 520px;
        }
        @include mobile-ipx {
          height: 470px;
        }
        @include mobile-sm {
          height: 400px;
        }
      }
    }
  }
}

// Quote form adjustment
#quote-content-right {
  .forms-quote {
    @include tablet {
      margin-top: 0 !important;
    }
  }
}

#quote-content-left {
  #left-content-text-wrapper {
    padding-bottom: 20px;
    padding-top: 54px;
  }
}

.qoute-callback-cta-desktop {
  @include tablet {
    display: none;
  }
}

.quote-callback-cta-mobile {
  @include tablet {
    margin: 20px 0 70px;
    display: block;
  }
}

.button-controls-container {
  @include tablet {
    width: 100% !important;
  }
}

.dot-navigation ul li {
  @include tablet {
    padding-bottom: 10px;
  }
}
