.container-960 {
  @include tablet {
    padding: 0 24px;
  }
}

.ica-hero {
  @include tablet {
    height: 530px;
  }
  @include mobile {
    margin-bottom: 158px;
  }

  &::before {
    @include tablet {
      height: 530px;
      width: 46%;
    }
    @include mobile {
      height: 508px;
    }
    @include mobile {
      width: 100%;
    }
  }
  &::after {
    @include tablet {
      height: 530px;
      width: 54%;
    }
    @include mobile {
      display: none;
    }
  }

  &--wrap {
    @include laptop-hd {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--content-left {
    @include tablet {
      height: 530px;
    }
    @include mobile {
      width: 100%;
      padding-bottom: 146px;
    }

    &__title {
      @include tablet {
        font-size: 38px;
      }
      @include mobile {
        text-align: center;
      }
    }

    .divider-2 {
      @include tablet {
        margin-left: 0;
      }
      @include mobile {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &--content-right {
    @include tablet {
      height: 530px;
    }
    @include mobile {
      width: 100%;
    }

    &__card {
      @include desktop {
        padding: 64px 54px;
        width: 103%;
      }
      @include tablet {
        padding: 54px 44px;
        top: 18%;
        width: 120%;

        p {
          @include tablet {
            font-size: 14px;
          }
        }
      }
      @include tablet-2 {
        top: 16%;
      }
      @include mobile {
        padding: 44px;
        top: -38%;
        width: 100%;
      }
    }
  }
}

.ica-main {
  &--head {
    &__title {
      @include tablet {
        font-size: 32px;
        margin-bottom: 60px;
      }
    }
  }

  &--pricipal-details {
    .pricipal-details-mobile-col-1 {
      @include tablet {
        width: 46%;
      }
      @include mobile {
        width: 100%;
      }
    }
    .pricipal-details-mobile-col-2 {
      @include tablet {
        width: 54%;
      }
      @include mobile {
        width: 100%;
      }
    }

    .pricipal-details--abn-number {
      label {
        @include tablet {
          max-width: 92%;
        }
      }
    }
  }

  &--drivers-conducting-services {
    &__top-wrap {
      .sample-id-note--desktop {
        @include tablet {
          display: none;
        }
      }
      .sample-id-note--mobile {
        @include tablet {
          display: block;
        }
      }
    }
    &__top-wrap-left {
      @include tablet {
        padding: 0 20px;
        width: 100%;
      }
    }
    &__top-wrap-right {
      @include tablet {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.ica-scroll {
  @include mobile {
    padding: 18px 0 18px 14px;
  }

  .content-scroll {
    @include tablet {
      height: 642px !important;
    }
  }

  &--inner {
    @include mobile {
      // padding-right: 0;
    }
  }
}

.ica-form-control {
  &--col-2 {
    @include mobile {
      width: 100%;
      padding: 0;
    }
  }
  &--col-3 {
    @include mobile {
      width: 100%;
      padding: 0;
    }
  }

  &--single-half {
    @include mobile {
      width: 100%;
    }
  }

  &--date {
    @include mobile {
      width: 100%;
    }
  }
}

.btn-main-ica {
  @include mobile-md {
    padding: 14px 20px;
  }
  @include mobile-ipx {
    font-size: 14px;
    padding: 14px 20px;
  }
}
