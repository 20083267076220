.home-hero {
	#banner-slider {
		@include mobile-sm {
			overflow: hidden !important;
		}
	}

	&--container {
		@include desktop-hd {
			height: 528px;
		}
		@include desktop {
			overflow: hidden;
		}
		@media (max-width: 992px) {
			margin-top: 44px;
		}
		@include tablet {
			height: 424px;
		}
		@include mobile-sm {
			height: 398px;
		}
		@include mobile-s7 {
			height: 372px;
		}
	}

	.bannerSlides {
		img {
			@include desktop-hd {
				width: auto !important;
				// width: 100%;
				height: 100%;
				object-fit: cover;
				max-height: 528px;
			}
			@include tablet {
				height: 424px !important;
			}
			@include mobile-sm {
				height: 398px !important;
			}
		}

		.text {
			@include laptop-hd2 {
				top: 15%;
			}
			@include laptop-hd {
				top: 13%;
			}
			@include desktop-hd {
				top: 10%;
				width: 85%;
			}
			@include desktop {
				top: calc(3% - 8px);
				width: 100%;
				height: calc(100% - 2px);
				padding: 12px 100px;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			@media (max-width: 992px) {
				top: 0%;
				height: 100%;
				padding: 12px 70px;

				h3.prata {
					line-height: 28px;
					width: 100% !important;
				}

				h1.montSetmi {
					line-height: 28px;
				}

				p {
					line-height: 20px;
				}

				.btn-learn-more {
					padding: 0 !important;
				}
			}

			@include tablet {
				margin-top: 0;
			}
			@include mobile {
				padding: 12px 40px;
			}
			@include mobile-s7 {
				padding: 12px 14px;
			}
		}
	}

	.inner-controls {
		@include desktop {
			right: -1.5%;
		}
		@media (max-width: 992px) {
			right: 1%;
		}
		@include tablet {
			right: 0%;
		}
		@include mobile-sm {
			right: -2%;
		}
	}
}
