.covid-page-intro {
	@include tablet {
		height: 676px;
	}
	@include mobile {
		height: 100%;
	}

	&--wrap {
		@include tablet {
			height: 100%;
		}
	}

	&--img-wrap {
		@include laptop-hd {
			width: 38.35%;
		}
		@include desktop-hd {
			width: 36%;
		}
		@include desktop {
			width: 32%;
		}
		@include tablet {
			display: none;
		}

		img {
			@include desktop-hd {
				max-width: 100%;
				height: calc(100vh - 130px);
				object-fit: cover;
			}
		}
	}

	&--content {
		@include laptop-hd {
			width: 61.65%;
		}
		@include desktop-hd {
			width: 64%;
		}
		@include desktop {
			width: 68%;
		}
		@include tablet {
			width: 100%;
			margin-top: 12px;
			padding: 22px;
		}
		@include mobile {
			padding: 12px;
		}
	}
	&--content-wrap {
		@include laptop-hd {
			top: 46px;
			right: 22px;
		}
		@include desktop-hd {
			top: 62px;
			width: 928px;
		}
		@include desktop {
			top: 40px;
			width: 800px;
			padding: 50px 70px;
		}
		@include tablet {
			position: relative;
			width: 100%;
			top: 0;
			left: 0;
			height: 100%;
		}
		@include mobile {
			padding: 34px 22px;
		}

		&__main-title {
			@include tablet {
				font-size: 36px;
			}
			@include mobile {
				font-size: 30px;
				line-height: 38px;
			}
		}
		&__title {
			@include tablet {
				font-size: 28px;
			}
			@include mobile {
				font-size: 20px;
			}
		}

		&__scroll {
			@include tablet {
				height: 330px !important;
			}
			@include mobile {
				height: 100% !important;
				padding: 0;
			}

			& > p:last-child {
				@include mobile {
					margin-bottom: 0;
				}
			}
		}
	}
}

.covid-page-main {
	&--head-img-wrap {
		@include desktop {
			height: 454px;
			padding-top: 90px;
			margin-bottom: 91px;
		}
		@include tablet {
			background: transparent;
			padding-top: 0;
			margin-bottom: 11px;
			height: 100%;
			margin-bottom: 0;
		}
		@include mobile {
		}

		&__img {
			@include desktop {
				width: 860px;
			}
			@include tablet {
				width: 100%;
				padding: 80px 20px 0;
			}
			@include mobile {
				padding: 50px 10px 0;
			}

			img {
				@include desktop {
					width: 860px;
				}
				@include tablet {
					width: 100%;
				}
			}
		}
	}

	&--content-wrap {
		@include mobile {
			padding-top: 50px;
		}
	}

	&--form-area {
		@include mobile {
			padding-bottom: 60px;
		}

		&__title {
			@include mobile {
				font-size: 28px;
			}
		}
		&__subtitle {
			@include tablet {
				max-width: 80%;
				margin: 0 auto 1rem;
				line-height: 24px;
			}
			@include mobile {
			}
		}
	}
}

.covid-form-wrap {
	@include desktop {
		max-width: 992px;
		margin: 60px auto 0;
	}
	@include tablet {
		margin: 40px 20px 0px;
		border: 1px solid #c7a652;
	}
	@include mobile {
		margin: 30px 20px 0px;
	}

	&--left {
		@include desktop {
			width: 22%;
		}
		@include tablet {
			display: none;
		}
	}
	&--right {
		@include desktop {
			padding: 100px 80px;
			width: 78%;
		}
		@include tablet {
			padding: 60px 54px;
			width: 100%;
		}
		@include mobile {
			padding: 50px 40px;
		}
		@include mobile {
			padding: 40px 24px;
		}
	}
}

.quiz-main-wrap {
	@include desktop {
		max-width: 860px;
	}
	@include tablet {
		max-width: 100%;
		padding: 0 50px 80px;
	}
	@include mobile {
		padding: 0 30px 50px;
	}
}

.quiz-modal {
	&--card {
		@include tablet {
			width: 96%;
			padding: 74px 0;
		}
	}
}

.quiz-modal {
	&--card {
		@include tablet {
			width: 96%;
			padding: 74px 0;
		}

		@include mobile {
			width: 94%;
			padding: 40px 0;
		}

		.icon-large {
			@include mobile {
				font-size: 64px;
			}
		}

		&__text-strong {
			@include mobile {
				max-width: 370px;
				margin: 0 auto 1.35rem;
				font-size: 18px;
				line-height: 28px;
			}
			@include mobile-ipx {
				max-width: 300px;
			}
		}

		&__form-wrap {
			@include tablet {
				margin: 0 auto;
			}
			@include mobile {
				width: 100%;
				max-width: 84%;
			}
		}

		&__form {
			.form-group-certificate-title {
				@include mobile {
					font-size: 14px;
					line-height: 20px;
				}
			}
			.form-group-certificate-item {
				@include mobile {
					margin-bottom: 4px;
					width: 100%;
				}

				.form-notification {
					@include mobile {
						font-size: 13px;
					}
				}
			}
		}
	}
}

.quiz-certificate-form {
	&.dispatch-success {
		.submit-notif-success-note {
			@include mobile {
				font-size: 16px;
				min-width: unset;
				top: -312%;
				min-width: 314px;
				margin-left: -158px;
				line-height: 24px;
			}
		}

		.submit-notif-success-back-to-home {
			@include mobile {
				top: -222%;
			}
		}
	}
}

.covid-main-form {
	&--btn-wrap {
		@include mobile {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.btn-covid {
				@include mobile {
					width: 100%;
					padding: 21px;
				}
			}

			a {
				@include mobile {
					width: 100%;
					text-align: center;
				}
			}
		}
	}

	&--notif-wrap {
		&__err-notif {
			@include mobile {
				position: fixed;
				top: unset;
				bottom: 16px;
				left: 50%;
				transform: translateX(-50%);
				width: 84%;
			}

			&:before {
			}
		}
	}
}
