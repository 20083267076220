@mixin desktop-fhd {
	@media (max-width: 1920px) {
		@content;
	}
}
@mixin desktop-hd-plus {
	@media (max-width: 1600px) {
		@content;
	}
}
@mixin laptop-hd2 {
	@media (max-width: 1440px) {
		@content;
	}
}

@mixin laptop-hd {
	@media (max-width: 1366px) {
		@content;
	}
}

@mixin desktop-hd {
	@media (max-width: 1280px) {
		@content;
	}
}

@mixin desktop {
	@media (max-width: 1024px) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: 768px) {
		@content;
	}
}

@mixin tablet-2 {
	@media (max-width: 720px) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: 480px) {
		@content;
	}
}
@mixin mobile-md {
	@media (max-width: 425px) {
		@content;
	}
}
@mixin mobile-ipx {
	@media (max-width: 414px) {
		@content;
	}
}
@mixin mobile-sm2 {
	@media (max-width: 394px) {
		@content;
	}
}
@mixin mobile-sm {
	@media (max-width: 375px) {
		@content;
	}
}
@mixin mobile-s7 {
	@media (max-width: 360px) {
		@content;
	}
}
@mixin mobile-ip5 {
	@media (max-width: 320px) {
		@content;
	}
}

// Drop Shadow 1
@mixin drop-shadow-1 {
	-webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}
